[
    {
        "practiceId": "VetCare",
        "vcDate": "2025-01-01"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-01-04"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-01-05"
    },
    {
        "practiceId": "Schilling-Knapp",
        "vcDate": "2025-01-11"
    },
    {
        "practiceId": "Schilling-Knapp",
        "vcDate": "2025-01-12"
    },
    {
        "practiceId": "Scotland",
        "vcDate": "2025-01-18"
    },
    {
        "practiceId": "Scotland",
        "vcDate": "2025-01-19"
    },
    {
        "practiceId": "Weisgerber",
        "vcDate": "2025-01-25"
    },
    {
        "practiceId": "Weisgerber",
        "vcDate": "2025-01-26"
    },
    {
        "practiceId": "Westermilies",
        "vcDate": "2025-02-08"
    },
    {
        "practiceId": "Westermilies",
        "vcDate": "2025-02-09"
    },
    {
        "practiceId": "Döring",
        "vcDate": "2025-02-15"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-02-16"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-02-22"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-02-23"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-03-01"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-03-02"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-03-08"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-03-09"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-03-22"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-03-23"
    },
    {
        "practiceId": "Burkert",
        "vcDate": "2025-03-29"
    },
    {
        "practiceId": "Burkert",
        "vcDate": "2025-03-30"
    },
    {
        "practiceId": "Domokos",
        "vcDate": "2025-04-05"
    },
    {
        "practiceId": "Domokos",
        "vcDate": "2025-04-06"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-04-12"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-04-03"
    },
    {
        "practiceId": "Scotland",
        "vcDate": "2025-04-18"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-04-19"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-04-20"
    },
    {
        "practiceId": "Döring",
        "vcDate": "2025-04-21"
    },
    {
        "practiceId": "Gräser",
        "vcDate": "2025-05-01"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-05-03"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-05-04"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-05-10"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-05-11"
    },
    {
        "practiceId": "Lambrecht",
        "vcDate": "2025-05-17"
    },
    {
        "practiceId": "Lambrecht",
        "vcDate": "2025-05-18"
    },
    {
        "practiceId": "Pfeiffer",
        "vcDate": "2025-05-24"
    },
    {
        "practiceId": "Pfeiffer",
        "vcDate": "2025-05-25"
    },
    {
        "practiceId": "Michels-Rosbach",
        "vcDate": "2025-05-29"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-05-31"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-06-01"
    },
    {
        "practiceId": "Schilling-Knapp",
        "vcDate": "2025-06-09"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-06-14"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-06-15"
    },
    {
        "practiceId": "Weisgerber",
        "vcDate": "2025-06-19"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-06-21"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-06-22"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-06-28"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-06-29"
    },
    {
        "practiceId": "Westermilies",
        "vcDate": "2025-07-05"
    },
    {
        "practiceId": "Westermilies",
        "vcDate": "2025-07-06"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-07-12"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-07-13"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-07-26"
    },
    {
        "practiceId": "Buhles",
        "vcDate": "2025-07-27"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-08-02"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-08-03"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-08-09"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-08-10"
    },
    {
        "practiceId": "Burkert",
        "vcDate": "2025-08-16"
    },
    {
        "practiceId": "Burkert",
        "vcDate": "2025-08-17"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-08-23"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-08-24"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-09-06"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-09-07"
    },
    {
        "practiceId": "Domokos",
        "vcDate": "2025-09-13"
    },
    {
        "practiceId": "Domokos",
        "vcDate": "2025-09-14"
    },
    {
        "practiceId": "Döring",
        "vcDate": "2025-09-20"
    },
    {
        "practiceId": "Döring",
        "vcDate": "2025-09-21"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-09-27"
    },
    {
        "practiceId": "Storck",
        "vcDate": "2025-09-28"
    },
    {
        "practiceId": "Gräser",
        "vcDate": "2025-10-03"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-10-04"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-10-05"
    },
    {
        "practiceId": "Lambrecht",
        "vcDate": "2025-10-18"
    },
    {
        "practiceId": "Lambrecht",
        "vcDate": "2025-10-19"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-10-25"
    },
    {
        "practiceId": "Louis",
        "vcDate": "2025-10-26"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-11-01"
    },
    {
        "practiceId": "Barth",
        "vcDate": "2025-11-02"
    },
    {
        "practiceId": "Pfeiffer",
        "vcDate": "2025-11-08"
    },
    {
        "practiceId": "Pfeiffer",
        "vcDate": "2025-11-09"
    },
    {
        "practiceId": "Michels-Rosbach",
        "vcDate": "2025-11-15"
    },
    {
        "practiceId": "Michels-Rosbach",
        "vcDate": "2025-11-16"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-11-29"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-11-30"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-12-06"
    },
    {
        "practiceId": "Creutz",
        "vcDate": "2025-12-07"
    },
    {
        "practiceId": "Schilling-Knapp",
        "vcDate": "2025-12-13"
    },
    {
        "practiceId": "Schilling-Knapp",
        "vcDate": "2025-12-14"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-12-20"
    },
    {
        "practiceId": "VetCare",
        "vcDate": "2025-12-21"
    },
    {
        "practiceId": "Scotland",
        "vcDate": "2025-12-24"
    },
    {
        "practiceId": "Weisgerber",
        "vcDate": "2025-12-25"
    },
    {
        "practiceId": "Westermilies",
        "vcDate": "2025-12-26"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-12-27"
    },
    {
        "practiceId": "Michelberger",
        "vcDate": "2025-12-28"
    },
    {
        "practiceId": "Beenen",
        "vcDate": "2025-12-31"
    }
]
