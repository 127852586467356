import practices from '../emergency/practices.json';
import schedule from '../emergency/schedule.json';

document.addEventListener("DOMContentLoaded", () => {
  // Helper: Format a phone number into a clickable tel: link.
  function formatTel(phoneNumber) {
    // Remove all non-digit characters
    let digits = phoneNumber.replace(/\D/g, '');
    // If it starts with 0, replace that 0 with +49
    if (digits.startsWith('0')) {
      digits = '+49' + digits.substring(1);
    } else if (!digits.startsWith('+')) {
      // Otherwise, if it doesn't start with a plus, prepend one
      digits = '+' + digits;
    }

    return 'tel:' + digits;
  }

  // Helper function to format dates.
  function formatDate(date) {
    return date.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  // Returns an array of schedule entries that are "active".
  // Active means the current time is within 12 hours before 08:00 on vcDate or during the period 08:00 to 22:00.
  function getActiveNotdienstEntries(schedule) {
    const now = new Date();

    return schedule.filter(entry => {
      if (!entry.vcDate) return false;
      const start = new Date(entry.vcDate + "T08:00:00");
      const end = new Date(entry.vcDate + "T21:00:00");
      const startMinus12 = new Date(start.getTime() - 12 * 3600 * 1000);
      return now >= startMinus12 && now <= end;
    }).sort((a, b) => {
      const aDate = new Date(a.vcDate + "T08:00:00");
      const bDate = new Date(b.vcDate + "T08:00:00");
      return aDate - bDate;
    });
  }

  async function displayNotdienstInfo(container) {
    try {
      const now = new Date();
      const isSaturdayBefore1800 = (now.getDay() === 6 && now.getHours() >= 9 && now.getHours() < 18);
      const isWeekdayBefore1800 = (now.getDay() !== 0 && now.getDay() !== 6 && now.getHours() >= 9 && now.getHours() < 18);

      // Get active entries based solely on vcDate.
      const activeEntries = getActiveNotdienstEntries(schedule);

      let html = '';

      // If it's a weekday before 18:00, add a Unser Service notice.
      if (isWeekdayBefore1800) {
        document.querySelectorAll(".emergency-weekday").forEach(el => el.style.display = "block");
      }

      // If it's Saturday before 18:00, add a Samstags-Service notice.
      if (isSaturdayBefore1800) {
        document.querySelectorAll(".emergency-saturday").forEach(el => el.style.display = "block");
      }

      // Display Notdienst info if there are active entries.
      if (activeEntries.length > 0) {
        activeEntries.forEach(entry => {
          const practice = practices.find(p => p.id === entry.practiceId);
          if (practice) {
            html += `
              <div class="emergency-entry">
                <p>${formatDate(new Date(entry.vcDate))}</p>  
                <p><strong>${practice.name}</strong></p>
                <p><a href="${practice.maps}" target="_blank">${practice.street}, ${practice.city}</a></p>
                <p><a href="${formatTel(practice.tel)}">${practice.tel}</a></p>
              </div>
            `;
          }
        });

        document.querySelectorAll(".emergency-hint").forEach(el => el.style.display = "block");

        container.innerHTML = html;
      } else {
        // If no active entries and it's not Saturday before 18:00, show an alternative message.
        if (!isSaturdayBefore1800) {
          document.querySelectorAll(".emergency-clinic").forEach(el => el.style.display = "block");
        } else {
          // If it's Saturday before 18:00 but no active entries exist, just show the Saturday message.
          container.innerHTML = html;
        }
      }
    } catch (error) {
      console.error("Error displaying emergency info:", error);
      document.getElementById(id).innerHTML = `<p>Fehler beim Laden der Notdienst-Informationen.</p>`;
    }
  }

  document.querySelectorAll(".emergency-entries").forEach(el => displayNotdienstInfo(el));
});
