[
  {
    "id": "VetCare",
    "name": "Tierarztpraxis VetCare",
    "street": "Mainzer Str. 65",
    "city": "67657 Kaiserslautern",
    "tel": "0631 310 397 05",
    "maps": "https://maps.app.goo.gl/xKe4HusTqqMR6isJ8"
  },
  {
    "id": "Beenen",
    "name": "Tierarztpraxis IVC Evidensia",
    "street": "Lothringer Dell 48",
    "city": "67659 Kaiserslautern",
    "tel": "0631 63063",
    "maps": "https://maps.app.goo.gl/6KKrS9A2EizmJ3NS8"
  },
  {
    "id": "Louis",
    "name": "Tierarztpraxis Louis",
    "street": "Karcherstraße 9",
    "city": "67655 Kaiserslautern",
    "tel": "0631 28000",
    "maps": "https://maps.app.goo.gl/1i8WnbxLr7sFLg278"
  },
  {
    "id": "Creutz",
    "name": "Tierarztpraxis Creutz",
    "street": "Leipziger Str. 108a",
    "city": "67663 Kaiserslautern",
    "tel": "0631 3577550",
    "maps": "https://maps.app.goo.gl/SjvQc7NSnMx5v9Wh8"
  },
  {
    "id": "Domokos",
    "name": "Tierarztpraxis Domokos",
    "street": "Brunnenstraße 11",
    "city": "66882 Hütschenhausen",
    "tel": "06371 9793601",
    "maps": "https://maps.app.goo.gl/FPX9k4ECHpJdFJqu9"
  },
  {
    "id": "Döring",
    "name": "Tierarztpraxis Döring",
    "street": "Wäschbacherhof 27a",
    "city": "67722 Winnweiler",
    "tel": "06302 983145",
    "maps": "https://maps.app.goo.gl/uwsQet6YXfmuavqi8"
  },
  {
    "id": "Gräser",
    "name": "Tierarztpraxis Gräser",
    "street": "Lindenstraße 3",
    "city": "67731 Otterbach",
    "tel": "06301 792814",
    "maps": "https://maps.app.goo.gl/UXS5ER2M92D4icBR7"
  },
  {
    "id": "Weisgerber",
    "name": "Tierarztpraxis Weisgerber",
    "street": "Pfaffenbergstraße 88",
    "city": "67663 Kaiserslautern",
    "tel": "0631 3112304",
    "maps": "https://maps.app.goo.gl/NhrXgH2MGRCXxic28"
  },
  {
    "id": "Lambrecht",
    "name": "Tierarztpraxis Lambrecht",
    "street": "Fahrlücke 10",
    "city": "67661 Kaiserslautern-Dansenberg",
    "tel": "0631 3037525",
    "maps": "https://maps.app.goo.gl/849ruUEw7aqMNbBK7"
  },
  {
    "id": "Pfeiffer",
    "name": "Tierarztpraxis Pfeiffer",
    "street": "Am Rauhen Weg 1A",
    "city": "67722 Winnweiler",
    "tel": "06302 2455",
    "maps": "https://maps.app.goo.gl/nQJZh3b7Lv7rs9Pn9"
  },
  {
    "id": "Storck",
    "name": "Tierarztpraxis Storck",
    "street": "Bremerstraße 4",
    "city": "67663 Kaiserslautern",
    "tel": "0631 3114990",
    "maps": "https://maps.app.goo.gl/LEp3e3zteEzHNUte8"
  },
  {
    "id": "Barth",
    "name": "Tierarztpraxis Barth",
    "street": "Jahnstraße 80",
    "city": "67659 Kaiserslautern",
    "tel": "06301 5552",
    "maps": "https://maps.app.goo.gl/ARk43QccUzedKLP67"
  },
  {
    "id": "Michels-Rosbach",
    "name": "Tierarztpraxis Michels-Rosbach",
    "street": "Gaustraße 1a",
    "city": "67655 Kaiserslautern",
    "tel": "0631 66599",
    "maps": "https://maps.app.goo.gl/QWkPNkuhjirWCDhP9"
  },
  {
    "id": "Schilling-Knapp",
    "name": "Tierarztpraxis Schilling-Knapp",
    "street": "Kaiserstraße 60",
    "city": "66849 Landstuhl",
    "tel": "06371 3300",
    "maps": "https://maps.app.goo.gl/T926n1YzSdY3DDpA8"
  },
  {
    "id": "Scotland",
    "name": "Tierarztpraxis Scotland",
    "street": "Friedenstraße 68A",
    "city": "67657 Kaiserslautern",
    "tel": "0631 93177",
    "maps": "https://maps.app.goo.gl/qjrGidyX1BDkAkA97"
  },
  {
    "id": "Burkert",
    "name": "Tierarztpraxis Burkert",
    "street": "Sembacher Str. 17",
    "city": "67677 Enkenbach-Alsenborn",
    "tel": "06303 8090180",
    "maps": "https://maps.app.goo.gl/6FbfJx5QWDVYiXWb6"
  },
  {
    "id": "Westermilies",
    "name": "Tierarztpraxis Westermilies",
    "street": "Richard-Wagner-Straße 76",
    "city": "67655 Kaiserslautern",
    "tel": "0631 28877",
    "maps": "https://maps.app.goo.gl/bwR4AQk6D6deEnmn9"
  },
  {
    "id": "Buhles",
    "name": "Tierarztpraxis Buhles",
    "street": "Bergstraße 19",
    "city": "67697 Otterberg",
    "tel": "06301 795008",
    "maps": "https://maps.app.goo.gl/QZN4hd8ELK7EM6uu5"
  },
  {
    "id": "Michelberger",
    "name": "Tierarztpraxis Michelberger",
    "street": "Kindsbacher Str. 9",
    "city": "66877 Ramstein-Miesenbach",
    "tel": "06371 70040",
    "maps": "https://maps.app.goo.gl/Govex97GLWRJC57h8"
  } 
]
